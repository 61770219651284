
import { defineComponent, onMounted, inject, reactive, ref } from "vue";
import { useRoute } from "vue-router";

import TableLite from "vue3-table-lite/ts";
import type { Component } from "vue";

import DeleteTestParticipantModal from "@/components/molecules/DeleteTestParticipantModal.molecules.vue";
import BackButton from "@/components/atoms/BackButton.vue";

// services
import projectService from "@/services/project.service";

// composables
import useDateFormatter from "@/composables/dateFormatter";

// interfaces
import { IDeleteReq } from "@/interfaces/participant";

export default defineComponent({
  components: {
    "table-lite": TableLite as unknown as Component,
    DeleteTestParticipantModal,
    BackButton,
  },
  setup() {
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Detail Asesmen"));

    const { formatDateTime } = useDateFormatter();

    const route = useRoute();

    interface IParticipant {
      email: string;
      started_at?: string;
      ended_at?: string;
      result: string | null;
      pdf_file: string | null;
    }

    interface IProjectTestDetail {
      project: {
        started_at: string;
        ended_at: string;
      };
      test: {
        id: number;
        name: string;
        image: string;
        description: string;
      };
      participants: {
        current_page: number;
        data: IParticipant[];
        from: number;
        to: number;
        total: number;
      };
    }

    let state = reactive({ testDetail: {} as IProjectTestDetail });
    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      const projectId = Number(route.params.projectId);
      const testId = Number(route.params.testId);

      const { data } = await projectService.getProjectTestDetail(
        projectId,
        testId,
        1,
        10
      );

      isLoading.value = false;
      state.testDetail = data;
    });

    const downloadReport = async () => {
      const projectId = Number(route.params.projectId);
      const testId = Number(route.params.testId);

      const { data } = await projectService.getProjectTestReport(
        projectId,
        testId
      );

      window.open(data, "_blank");
    };

    // Init Your table settings
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Peserta",
          field: "email",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Tanggal Mulai Tes",
          field: "started_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Tanggal Selesai Tes",
          field: "ended_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Hasil",
          field: "result",
          width: "10%",
          sortable: true,
        },
        {
          label: "Aksi",
          field: "action",
          width: "5%",
          sortable: false,
        },
      ],
      rows: [] as IParticipant[],
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    /**
     * Table search event
     */
    const doSearch = async (
      offset: number,
      limit: number,
      order: string,
      sort: string
    ) => {
      const projectId = Number(route.params.projectId);
      const testId = Number(route.params.testId);
      const page = offset / limit + 1;

      table.isLoading = true;
      const { data } = await projectService.getProjectTestDetail(
        projectId,
        testId,
        page,
        limit
      );
      table.rows = data.participants.data.map((d: IParticipant) => {
        d.started_at = formatDateTime(data.project.started_at, "DD/MM/YYYY");
        d.ended_at = formatDateTime(data.project.ended_at, "DD/MM/YYYY");

        return d;
      });

      table.totalRecordCount = data.participants.total;
      table.isLoading = false;
    };

    /**
     * Table search finished event
     */
    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    // Get data first
    doSearch(0, 10, "id", "asc");

    // Actions
    const downloadResult = async (participantId: number) => {
      table.isLoading = true;

      const projectId = Number(route.params.projectId);
      const testId = Number(route.params.testId);

      const { data } = await projectService.getProjectTestResult(
        projectId,
        testId,
        participantId
      );

      table.isLoading = false;

      window.open(data, "_blank");
    };

    const showDeleteModal = ref<null | { show: (data: IDeleteReq) => null }>(
      null
    );
    const actionDelete = (participantId: number) => {
      const projectId = Number(route.params.projectId);
      const testId = Number(route.params.testId);
      if (showDeleteModal.value)
        showDeleteModal.value.show({
          participantId,
          testId,
          projectId,
        });
    };

    return {
      state,
      isLoading,
      table,
      downloadReport,
      downloadResult,
      showDeleteModal,
      actionDelete,
      doSearch,
      tableLoadingFinish,
    };
  },
});
