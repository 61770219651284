
import { AxiosError } from "axios";
import { ref, inject } from "vue";
import { useToast } from "vue-toastification";

import projectService from "@/services/project.service";
import { IDeleteReq } from "@/interfaces/participant";

export default {
  setup(_: any, { emit }: any) {
    const toast = useToast();
    const getProfile = inject("getProfile") as CallableFunction;

    const isShown = ref<boolean>(false);
    const projectId = ref<number>(0);
    const testId = ref<number>(0);
    const participantId = ref<number>(0);
    const show = (data: IDeleteReq) => {
      projectId.value = data.projectId;
      testId.value = data.testId;
      participantId.value = data.participantId;
      isShown.value = true;
    };
    const close = () => (isShown.value = false);
    const remove = async () => {
      try {
        await projectService.deleteTestParticipant(
          projectId.value,
          testId.value,
          participantId.value
        );
        await getProfile();
        toast.success("Berhasil menghapus data");
        emit("onSuccess", 0, 10, "id", "asc");
        close();
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    return {
      remove,
      show,
      close,
      isShown,
    };
  },
};
