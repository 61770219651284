<template>
  <button
    class="text-blue-900 text-sm font-semibold mb-2 flex items-center gap-2"
    @click="goBack"
  >
    <img src="@/assets/img/icons/back.svg" class="w-3 h-3" />
    Kembali
  </button>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack,
    };
  },
};
</script>
